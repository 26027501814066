import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import AuthRoute from "./middleware/AuthRoute";
import { WalletChatWidget } from "react-wallet-chat";
import "react-wallet-chat/dist/index.css";
import { useSelector } from "react-redux";
import Hotjar from "@hotjar/browser";
// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));
const MyWalletInfo = React.lazy(() =>
  import("./components/pages/MyWalletInfo")
);
const ListNFT = React.lazy(() => import("./components/pages/ListNFT"));

// Home Pages
const Home = React.lazy(() => import("./components/pages/Home"));
const Resetpassword = React.lazy(() =>
  import("./components/pages/Resetpassword")
);
const Forgotpassword = React.lazy(() =>
  import("./components/pages/Forgotpassword")
);
const Verifyemail = React.lazy(() => import("./components/pages/Verifyemail"));
const SegmintNFT = React.lazy(() => import("./components/pages/SegmintNFT"));
const Hometwo = React.lazy(() => import("./components/pages/Hometwo"));
const Homethree = React.lazy(() => import("./components/pages/Homethree"));
// const Homefour = React.lazy(() => import("./components/pages/Homefour"));
const Homefive = React.lazy(() => import("./components/pages/Homefive"));
// Blog
const Bloggrid = React.lazy(() => import("./components/pages/Bloggrid"));
const Bloglist = React.lazy(() => import("./components/pages/Bloglist"));
const Blogsingle = React.lazy(() => import("./components/pages/Blogsingle"));
// Pages
const About = React.lazy(() => import("./components/pages/About"));
const Services = React.lazy(() => import("./components/pages/Services"));
const Faq = React.lazy(() => import("./components/pages/Faq"));
const Pricing = React.lazy(() => import("./components/pages/Pricing"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const Comingsoon = React.lazy(() => import("./components/pages/Comingsoon"));
const Error = React.lazy(() => import("./components/pages/Error"));
const Login = React.lazy(() => import("./components/pages/Login"));
const Register = React.lazy(() => import("./components/pages/Register"));
const Legal = React.lazy(() => import("./components/pages/Legal"));
// Listings
const Listinggrid = React.lazy(() => import("./components/pages/Listinggrid"));
const Listinglist = React.lazy(() => import("./components/pages/Listinglist"));
const Listingmap = React.lazy(() => import("./components/pages/Listingmap"));
const Listingdetailsone = React.lazy(() =>
  import("./components/pages/Listingdetailsone")
);
const Listingdetailstwo = React.lazy(() =>
  import("./components/pages/Listingdetailstwo")
);
const Listingdetailsthree = React.lazy(() =>
  import("./components/pages/Listingdetailsthree")
);
const Submitlisting = React.lazy(() =>
  import("./components/pages/Submitlisting")
);
const Comparelistings = React.lazy(() =>
  import("./components/pages/Comparelistings")
);
// Agents
const Agentarchive = React.lazy(() =>
  import("./components/pages/Agentarchive")
);
const Agentdetails = React.lazy(() =>
  import("./components/pages/Agentdetails")
);
const Profile = React.lazy(() => import("./components/pages/Profile"));
const Profilelistings = React.lazy(() =>
  import("./components/pages/Profilelistings")
);
const Profilesavedlistings = React.lazy(() =>
  import("./components/pages/Profilesavedlistings")
);
const ProfileTransaction = React.lazy(() =>
  import("./components/pages/ProfileTransaction")
);
const KycVerify = React.lazy(() => import("./components/pages/KycVerify"));
// Agency
const Agencyarchive = React.lazy(() =>
  import("./components/pages/Agencyarchive")
);
const Agencydetails = React.lazy(() =>
  import("./components/pages/Agencydetails")
);

function App() {
  const widgetStateFromRedux = useSelector((state) => state.wallet.widgetState);
  ReactGA.initialize(process.env.REACT_APP_GTAG);
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
  });
  const siteId = process.env.REACT_APP_HOTJAR;
  console.log("process.env.REACT_APP_HOTJAR", process.env.REACT_APP_HOTJAR);
  const hotjarVersion = 6;
  Hotjar.init(siteId, hotjarVersion);
  return (
    <>
      <div className="walletchatwidget">
        <WalletChatWidget widgetState={widgetStateFromRedux} />
      </div>
      <Suspense fallback={<div></div>}>
        <Preloader />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/signup/:id/:emailparam" element={<Register />} />

          <Route path="/reset-password" element={<Resetpassword />} />
          <Route path="/forgot-password" element={<Forgotpassword />} />
          <Route path="/verify-email" element={<Verifyemail />} />
          <Route element={<AuthRoute />}>
            <Route exact path="/" element={<Home />} />
            <Route path="/home-v2" element={<Hometwo />} />
            <Route path="/home-v3" element={<Homethree />} />
            <Route path="/home-v5" element={<Homefive />} />
            <Route path="/blog-grid" element={<Bloggrid />} />
            <Route path="/blog-list" element={<Bloglist />} />
            <Route path="/blog-single" element={<Blogsingle />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/coming-soon" element={<Comingsoon />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/listing-grid" element={<Listinggrid />} />
            <Route path="/listing-list" element={<Listinglist />} />
            <Route path="/listing-map" element={<Listingmap />} />
            <Route
              path="/listing-details-v1/:id"
              element={<Listingdetailsone />}
            />
            <Route
              path="/listing-details-v1/:id/:creatorWallet/"
              element={<Listingdetailsone />}
            />{" "}
            <Route
              path="/listing-details-v1/:id/"
              element={<Listingdetailsone />}
            />
            <Route path="/listing-details-v2" element={<Listingdetailstwo />} />
            <Route
              path="/listing-details-v3"
              element={<Listingdetailsthree />}
            />
            <Route path="/submit-listing" element={<Submitlisting />} />
            <Route path="/compare-listings" element={<Comparelistings />} />
            {/* Agents */}
            <Route path="/agent-archive" element={<Agentarchive />} />
            <Route path="/agent-details" element={<Agentdetails />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile-listings" element={<Profilelistings />} />
            <Route path="/my-wallet" element={<MyWalletInfo />} />
            <Route path="/kyc-verify" element={<KycVerify />} />
            <Route
              path="/profile-saved-listings"
              element={<Profilesavedlistings />}
            />
            <Route path="/transaction-panel" element={<ProfileTransaction />} />
            {/* Agency */}
            <Route path="/agency-archive" element={<Agencyarchive />} />
            <Route path="/agency-archive/:id" element={<Agencyarchive />} />
            <Route path="/agency-details" element={<Agencydetails />} />
            <Route path="/re-my-nft" element={<SegmintNFT />} />
            <Route path="/list-my-nft" element={<ListNFT />} />
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
