import axios from "axios";
import AuthService from "../Services/AuthService";
import { errorMsgHandler } from "../utils/message";

export const getConfig = () => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return config;
};

export const userSignup = async (path, body) => {
  const config = getConfig();
  const result = await axios
    .post(`${process.env.REACT_APP_BACKEND_URL}${path}`, body, config)
    .then((res) => {
      console.log("user", res);
      const result = {
        status: true,
        message: "User registered successfully!",
      };
      return result;
    })
    .catch((error) => {
      console.log("error", error.response);
      if (error.response.data.errors.msg[0].msg) {
        const msg = errorMsgHandler(error.response.data.errors.msg[0].msg);
        const result = {
          status: false,
          message: msg,
        };
        return result;
      }
      const msg = errorMsgHandler(error.response.data.errors.msg);
      const result = {
        status: false,
        message: msg,
      };
      return result;
    });

  return result;
};

export const userLogin = async (path, body) => {
  const authService = AuthService.get();
  const config = getConfig();
  const result = await axios
    .post(`${process.env.REACT_APP_BACKEND_URL}${path}`, body, config)
    .then((res) => {
      const token = {
        accessToken: res.data.token,
        refreshToken: res.data.refreshToken,
        accessExp: res.data.expiry,
        refreshExp: res.data.refreshExpiry,
      };
      const authUser = res.data.user;
      authService.login(token, authUser);
      const result = {
        status: true,
        message: "Login Successfully!",
        authUser: authUser,
      };
      return result;
    })
    .catch((error) => {
      console.log("error", error);
      if (error.response.data.errors.msg[0].msg) {
        const msg = errorMsgHandler(error.response.data.errors.msg[0].msg);
        const result = {
          status: false,
          message: msg,
        };
        return result;
      }
      const msg = errorMsgHandler(error.response.data.errors.msg);
      const result = {
        status: false,
        message: msg,
      };
      return result;
    });

  return result;
};

export const clearDynamicCache = () => {
  localStorage.removeItem("dynamic_project_settings");
  localStorage.removeItem("dynamic_networks");
  localStorage.removeItem("dynamic_connected_wallet_connector");
  localStorage.removeItem("dynamic_nonce");
  localStorage.removeItem("dynamic_primary_wallet_id");
  localStorage.removeItem("dynamic_authentication_token");
  localStorage.removeItem("dynamic_authenticated_user");
};

export const authDynamic = async (authToken) => {
  const authService = AuthService.get();
  const url = `${process.env.REACT_APP_BACKEND_URL}/authDynamic`;
  const requestBody = {
    authToken: authToken,
  };
  try {
    const response = await axios.post(url, requestBody);
    if (response.data.status) {
      const token = {
        accessToken: response.data.token,
        refreshToken: response.data.refreshToken,
        accessExp: response.data.expiry,
        refreshExp: response.data.refreshExpiry,
      };
      const authUser = response.data.user;
      authService.login(token, authUser);
      return {
        status: true,
        message: "Login successful!",
      };
    }
    return {
      isUserNotRegistered: +response?.status === 204 ? true : false,
      status: false,
      message: response.data.message,
    };
  } catch (error) {
    console.log(error);
    clearDynamicCache();
    return {
      status: false,
      message: error?.message
        ? error.message
        : "An error occured while trying to log in!",
    };
  }
};

export const userSignupWithDynamic = async (body) => {
  try {
    const authService = AuthService.get();
    let result = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/users/onBoard`,
      body
    );
    if (result.data.status) {
      const token = {
        accessToken: result.data.token,
        refreshToken: result.data.refreshToken,
        accessExp: result.data.expiry,
        refreshExp: result.data.refreshExpiry,
      };
      const authUser = result.data.user;
      authService.login(token, authUser);
      return {
        status: true,
        message: result.data.message,
      };
    }
    clearDynamicCache();
    return {
      status: false,
      message: result.data.message,
    };
  } catch (error) {
    clearDynamicCache();
    return {
      status: false,
      message: error?.response?.data?.errors?.msg
        ? error?.response?.data?.errors?.msg
        : error?.message
        ? error.message
        : "An error occurred while trying to register!",
    };
  }
};
export const refreshTokenFn = async (path, body) => {
  // const authService = AuthService.get();
  // const config = getConfig();
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  // console.log("tt", body.refreshToken);
  const result = await axios
    .post(`${process.env.REACT_APP_BACKEND_URL}${path}`, body, config)
    .then((res) => {
      // console.log("refres", res);
      const token = {
        accessToken: res.data.token,
        refreshToken: res.data.refreshToken,
        accessExp: res.data.expiry,
        refreshExp: res.data.refreshExpiry,
      };
      // const authToken = res.data.token;
      const user = res.data.user;
      return {
        status: true,
        token: token,
        user: user,
      };
    })
    .catch((err) => {
      console.log("error", err);
      return {
        status: false,
      };
    });

  return result;
};
