import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  walletInfo: {},
  widgetState: {
    isOpen: false,
    chatAddr: "",
  },
};

export const wallet = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setWalletInfo: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.walletInfo = action.payload;
    },
    setWidgetState: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.widgetState = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setWalletInfo, setWidgetState } = wallet.actions;

export default wallet.reducer;
