export const errorMsgHandler = (msg) => {
  switch (msg) {
    case "USER_DOES_NOT_EXIST":
      return "Incorrect email or password.";
    case "WRONG_PASSWORD":
      return "Incorrect email or password.";
    case "EMAIL_IS_NOT_VALID":
      return "Invalid email.";
    case "USER_ALREADY_EXISTS":
      return "Email is invalid or already taken.";
    case "USER_NAME_ALREADY_EXISTS":
      return "User name is invalid or already taken.";
    case "NOT_A_VALID_URL":
      return "Invalid url.";
    case "COLLECTION_ALREADY_EXISTS":
      return "This product is already exits";
    case "PRODUCT_ALREADY_EXISTS":
      return "This product is already exits";
    case "PASSWORD_CHANGED":
      return "Password changed successfully!";
    case "INVALID_TOKEN":
      return "Please try again";
    case "PASSWORD_NOT_SET":
      return "Please Sign In with google";
    case "PASSWORD_TOO_SHORT_MIN_5":
      return "Incorrect email or password.";
    case "NOT_FOUND":
      return "Sorry, we could not find your account.";
    case "Invalid value":
      return "Incorrect email or password.";
    default:
      return "Something is wrong";
  }
};
