import React from "react";
import { DynamicContextProvider } from "@dynamic-labs/sdk-react";
import { DynamicWagmiConnector } from "@dynamic-labs/wagmi-connector";
import { useLocation, useNavigate } from "react-router";
import { authDynamic } from "../apis/authApisFn";
import { toast } from "react-toastify";

const evmNetworks = [
  {
    blockExplorerUrls: ["https://testnet.snowtrace.io/"],
    chainId: 43113,
    chainName: "Avalanche Testnet C-Chain",
    iconUrls: ["https://app.dynamic.xyz/assets/networks/avax.svg"],
    nativeCurrency: {
      decimals: 18,
      name: "AVAX",
      symbol: "AVAX",
    },
    networkId: 43113,
    shortName: "avax",
    rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
    vanityName: "Avalanche Testnet",
  },
  {
    blockExplorerUrls: ["https://polygonscan.com/"],
    chainId: 80001,
    chainName: "Polygon",
    iconUrls: ["https://app.dynamic.xyz/assets/networks/polygon.svg"],
    nativeCurrency: {
      decimals: 18,
      name: "Polygon",
      symbol: "MATIC",
    },
    networkId: 80001,
    shortName: "matic",
    rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],
    vanityName: "Polygon Testnet",
  },
];
export default function WagmiComponent({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  let authenticateUser = async (authToken) => {
    try {
      let result = await authDynamic(authToken);

      if (result.status) {
        navigate("/");
        toast.success("Login Successfully!");
      } else if (result.isUserNotRegistered) {
        // setModelShow(true);
        toast.success("User is not registed");
      } else {
        toast.error(result.message || "User is not registed");
      }
      // setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <DynamicContextProvider
      settings={{
        environmentId: process.env.REACT_APP_DYNAMIC_XYZ_ENVIRONMENT_ID,
        initialAuthenticationMode: "connect-and-sign",
        eventsCallbacks: {
          onAuthSuccess: (args) => {
            // eslint-disable-next-line no-unused-expressions
            location.pathname === "/login"
              ? authenticateUser(args?.authToken)
              : "";
          },
        },
        multiWallet: true,
        evmNetworks,
      }}
    >
      <DynamicWagmiConnector evmNetworks={evmNetworks}>
        {children}
      </DynamicWagmiConnector>
    </DynamicContextProvider>
  );
}
