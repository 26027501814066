import {
  configureStore,
  combineReducers,
  // getDefaultMiddleware,
} from "@reduxjs/toolkit";
import walletReducer from "./wallet";
import userReducer from "./user";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
const combinedReducer = combineReducers({
  wallet: walletReducer,
  user: userReducer,
});

const persistConfig = {
  key: "counter",
  storage,
};

const rootReducer = (state, action) => {
  if (action.type === "counter/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  // middleware: [...getDefaultMiddleware()],
});

const persistor = persistStore(store);

export { store, persistor };
