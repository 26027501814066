class AuthService {
  static instance = null;
  authToken = null;
  user = null;

  static get() {
    if (AuthService.instance == null) {
      console.log("AuthService initializing");
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }

  login = (authToken, user) => {
    this.authToken = authToken.accessToken;
    this.user = user;
    localStorage.setItem("token", authToken.accessToken);
    localStorage.setItem("refresh_token", authToken.refreshToken);
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("access_expire", authToken.accessExp);
    localStorage.setItem("refresh_expire", authToken.refreshExp);
  };

  logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
    localStorage.removeItem("access_expire");
    localStorage.removeItem("refresh_expire");
    localStorage.removeItem("account");
    localStorage.setItem("isWalletConnected", false);
    localStorage.setItem("verified", false);
    localStorage.removeItem("dynamic_project_settings");
    localStorage.removeItem("dynamic_networks");
    localStorage.removeItem("dynamic_connected_wallet_connector");
    localStorage.removeItem("dynamic_nonce");
    localStorage.removeItem("dynamic_primary_wallet_id");
    localStorage.removeItem("dynamic_authentication_token");
    localStorage.removeItem("dynamic_authenticated_user");

    this.authToken = null;
    this.user = null;
    if (localStorage.getItem("token") === null) {
      return true;
    }

    return false;
  };

  isAuthenticated() {
    if (this.authToken === null) {
      try {
        const authTokenFromStorage = localStorage.getItem("token");
        if (authTokenFromStorage) {
          this.authToken = authTokenFromStorage;
          this.user = localStorage.getItem("user");
        }
      } catch (e) {
        console.log(e);
        this.authToken = null;
        this.user = null;
      }
    }
    return this.authToken;
  }

  getAuthToken() {
    if (!this.isAuthenticated()) {
      console.log("not authenticated!");
    }
    return this.authToken;
  }

  getRefreshToken() {
    const refreshToken = localStorage.getItem("refresh_token");
    return refreshToken;
  }

  getAccessExpTime() {
    const accessExpTime = localStorage.getItem("access_expire");
    return accessExpTime;
  }

  getRefreshExpTime() {
    const accessExpTime = localStorage.getItem("refresh_expire");
    return accessExpTime;
  }

  getUser() {
    if (!this.isAuthenticated()) {
      console.log("not authenticated!");
    }
    return this.user;
  }
}

export default AuthService;
